<template>
  <div
    v-if="activeBooking"
    class="booking-details-wrapper app-negative-margin-top"
  >
    <div class="summary-header">
      <div class="summary-header-image-wrapper">
        <img
          v-if="!imageLoadFailed && display.imageUrl"
          class="summary-header-image"
          :src="display.imageUrl"
          alt="City"
          @error="imageLoadFailed = true"
        />
        <div
          v-if="!imageLoadFailed && display.imageUrl"
          class="summary-header-image-bg"
        ></div>
        <div v-else class="summary-header-image-placeholder">
          <IconPlaceholderCityDark
            style="position: relative; top: -7%; right: -45px; color: white"
          />
        </div>
      </div>
      <div class="container-xs summary-header-content">
        <AppNavButton icon="back" :to="backPath" />
        <p class="summary-header-content-city">{{ display.cityName }}</p>
        <p class="summary-header-content-region">{{ display.cityRegion }}</p>
        <p class="summary-header-content-dates">{{ display.date }}</p>
        <p class="summary-header-content-id">Trip ID: {{ display.id }}</p>
      </div>
    </div>

    <div class="booking-details-content-wrapper container-xs">
      <div class="booking-details-content">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
  <LoadingModalFullScreen
    v-else
    ref="bookingLoadingModal"
    text="Loading booking details."
    non-closable
  />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AppNavButton from '../../components-rf/AppNavButton.vue'
import IconPlaceholderCityDark from '../../components-rf/icons/placeholders/IconPlaceholderCityDark.vue'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
import { date } from '../../other/utils/helpers/date.js'

const formatTripDate = timestamp => date.formatUTC('-ms -d, -y', timestamp)

export default {
  name: 'BookingsDetails',
  components: {
    LoadingModalFullScreen,
    IconPlaceholderCityDark,
    AppNavButton,
  },
  created() {
    if (this.activeBooking?.eludeRef !== this.eluderef) {
      this.clearActiveBooking()
      this.getBookingByReference(this.eluderef)
        .then(booking => {
          if (!booking) this.router.push('/bookings')
          else this.setActiveBooking(booking)
        })
        .catch(() => this.router.push('/bookings'))
    }
  },
  mounted() {
    this.$watch(
      () => this.activeBooking,
      booking => {
        booking
          ? this.$refs.bookingLoadingModal?.hide()
          : this.$refs.bookingLoadingModal.show()
      },
      { immediate: true },
    )
  },
  data() {
    return {
      imageLoadFailed: false,
    }
  },
  methods: {
    ...mapMutations('BookingModule', [
      'setActiveBooking',
      'clearActiveBooking',
    ]),
    ...mapActions('BookingModule', ['getBookingByReference']),
  },
  computed: {
    ...mapState('BookingModule', ['activeBooking']),
    eluderef() {
      return this.$route.query.eluderef
    },
    backPath() {
      return {
        'booking-details': '/bookings',
        'booking-flight-details': `/bookings/details?eluderef=${this.eluderef}`,
        'booking-hotel-details': `/bookings/details?eluderef=${this.eluderef}`,
      }[this.$route.name]
    },
    display() {
      const trip = this.activeBooking
      const departDate = formatTripDate(trip.departureDate)
      const returnDate = formatTripDate(trip.returnDate)
      return {
        date: `${departDate} - ${returnDate}`,
        id: trip.eludeReference,
        cityName: trip.arrivalCity.name,
        cityRegion: trip.arrivalCity.region.name,
        imageUrl: trip.arrivalCity.media.imageUrl,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.booking-details-wrapper {
  position: absolute;
  width: 100%;
}

.summary-header {
  --min-height: 250px;
  min-height: var(--min-height);
  position: sticky;
  overflow: hidden;
}
.summary-header-content {
  position: relative;
  min-height: var(--min-height);
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include s {
    padding-bottom: 4rem;
  }
  display: grid;
  grid-template-rows: 1fr auto auto auto;
}
.summary-header-content-city {
  font-family: GTSuper;
  font-style: italic;
  font-size: 3rem;
  color: white;
  line-height: 1.2;
  text-shadow: var(--text-shadow);
}
.summary-header-content-region {
  font-family: SpaceMono;
  font-size: 1.2rem;
  color: white;
  text-shadow: var(--text-shadow);
}
.summary-header-content-dates {
  font-family: DDin;
  padding: 0.25rem 0.6rem;
  background: var(--color-secondary);
  justify-self: flex-start;
  font-size: 13px;
  border-radius: 9px;
  margin-top: 0.2rem;
}
.summary-header-content-id {
  font-family: DDin;
  padding: 0.25rem 0.6rem;
  background: #444;
  color: white;
  justify-self: flex-start;
  font-size: 13px;
  border-radius: 9px;
  margin-top: 0.5rem;
}
.summary-header-image-wrapper {
  height: 100%;
  & > * {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.summary-header-image {
  object-fit: cover;
  object-position: center;
  @include m {
    filter: blur(4px);
  }
}
.summary-header-image-placeholder {
  background: var(--color-icon-background-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary-header-image-bg {
  position: absolute;
  background: #0004;
  background: linear-gradient(#0000 30%, #0008 100%);
}
.booking-details-content-wrapper {
  padding-bottom: 2rem;
  position: relative;
  @include s {
    top: -3rem;
  }
}
.booking-details-content {
  padding-top: 1.5rem;
  @include s {
    padding: 1.5rem 1rem;
    background: white;
    box-shadow: var(--shadow-app);
    background: darker(get('color.white'), 1%);
    border-radius: 25px;
  }
}
</style>
